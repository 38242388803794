import {
  Banner,
  Box,
  ContactBox,
  ContactUsEmailLink,
  ContactUsPhoneLink,
  Container,
  Heading,
  Helmet,
  Link,
  PreStyledComponents,
  Row,
  Section,
  StandardButton,
  TickListItem,
  YouTubeVideo,
  routesObject
} from "@life-without-barriers/react-components"

import { DisabilityForm } from "@life-without-barriers/shared-contact-form"
import { IGatsbyImageData } from "gatsby-plugin-image"
import Layout from "../../../components/disability/Layout"
import React from "react"
import { STAListing } from "../../../contentTypes"
import SilListingCard from "../../../components/disability/SilListingCard"
import { Site } from "@life-without-barriers/gatsby-common"
import { graphql } from "gatsby"
import { pageUrls } from "@life-without-barriers/utilities"
import scrollIntoView from "scroll-into-view"

const { createStaListingUrl } = pageUrls
const { GridWrapper } = PreStyledComponents

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
    listings: {
      edges: Array<{
        node: Pick<
          STAListing,
          | "slug"
          | "title"
          | "state"
          | "listingDescription"
          | "ageSuitability"
          | "featureImage"
        >
      }>
    }
  }
}

const bannerBreadcrumbs = [
  routesObject.disabilityHome,
  {
    to: "/disability/services",
    text: "Disability services",
    title: "Return navigation"
  }
]

const scrollToElement = (elementId: string) => {
  const target = document.getElementById(elementId) as HTMLElement
  if (target) {
    scrollIntoView(target, {
      time: 1000,
      align: {
        topOffset: 80
      }
    })
  }
}

const ageSuitabilityShort: Record<string, string> = {
  "Adults (18 to 65 years of age)": "Adults",
  "Children & young people (7 to 18 years of age)": "Children & young people"
}

const ShortTermAccommodation = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage,
    listings
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`Short Term Accomodation | ${siteMetadata.title}`}
        description="Support for carers and the families of people living with disability"
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={bannerImage}
      />
      <Layout
        fixedCTAPhoneNumber="1800 610 699"
        fixedCTAContact={
          <DisabilityForm
            formId="disability-sta-fixed-cta"
            formKind="disability-home-living"
            formTitle="Contact Us"
          />
        }
      >
        <Banner
          title="Short Term Accommodation (Respite)"
          subTitle="A home away from home for people with a disability."
          breadcrumbs={bannerBreadcrumbs}
          image={bannerImage}
        />
        <Container>
          <Row className="relative flex-wrap">
            <Box className="w-100 w-seven-twelfths-l layout-gap">
              <Heading size={2}>
                What is Short Term Accommodation (STA)?
              </Heading>
              <p>
                Short Term Accommodation (STA), sometimes known as respite, is a
                great opportunity for people living with a disability to have a
                break from their daily routine with the support of highly
                skilled staff in purpose-built accommodation.
              </p>
              <p>
                Life Without Barriers&apos; STAs are inclusive and enjoyable
                places for all short stays. We offer a range of services
                designed to meet each person&apos;s individual needs, while also
                incorporating engaging activities to build skills to promote
                independence and the chance to meet new people.
              </p>
              <p>
                We have a number of STA houses across New South Wales, Victoria
                and Western Australia with more coming in other states and
                territories.
              </p>
              <p>
                Our priority is ensuring a safe and comfortable experience for
                every person we support, so both guests and their families enjoy
                a well-earned short break.
              </p>
            </Box>
            <Box className="w-100 w-third-l mt5 mt0-l absolute-l top-0-l right-0-l">
              <div className="dn db-l">
                <DisabilityForm
                  formId="disability-sta-contact-us-l"
                  formKind="disability-home-living"
                  formTitle="Contact Us"
                />
              </div>
              <div className="db dn-l center tc mt3">
                <StandardButton
                  className="w-100 center"
                  shade="xdark"
                  onClick={() =>
                    scrollToElement("disability-sta-contact-us-sm")
                  }
                >
                  Enquire Now
                </StandardButton>
              </div>
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row>
              <Box className="w-100 w-seven-twelfths-l layout-gap">
                <Heading size={2}>About our houses</Heading>
                <p>
                  You can take a guided tour of our houses before confirming
                  your stay. Our respite and STA:
                </p>
                <ul className="mt4 pl1 mb0">
                  {[
                    "Have 24/7 staffing services",
                    "Provide a range of stay options which can include hourly, overnight, day, weekend, holiday or longer period stays",
                    "Prioritise each individual's needs, including matching personalities and ages to ensure they enjoy their stay",
                    "Give guests a chance to learn new skills, participate in community activities and make new friends"
                  ].map((text, i) => (
                    <TickListItem
                      key={i}
                      text={text}
                      className={i === 0 ? "w-90-ns" : "mt3 w-90-ns"}
                      textClasses="pt0 pt1-ns"
                    />
                  ))}
                </ul>
              </Box>
            </Row>
          </Container>
        </Section>
        <Container>
          <Row>
            <Box className="w-100 w-seven-twelfths-l layout-gap">
              <Heading size={2}>Meet some of the people we support</Heading>
              <p>
                Kathleen, Maree, Matthew and Richard tell us why they like to
                visit Devonshire Road, one of Life Without Barriers STAAs for
                people with disabilities.
              </p>
              <YouTubeVideo
                title="Meet some of the people with disabilities we support at our STAA in Victoria"
                videoId="qmAzr5RHVlA"
                className="mt4"
              />
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row>
              <Box>
                <Heading size={2}>Our short-term respite centres</Heading>
                <p className="layout-readable">
                  Our short-term respite, STA, services are the perfect home
                  away from home for people with a disability to enjoy a weekend
                  escape or a holiday destination for longer stays. Our friendly
                  staff accommodate your individual needs and requirements,
                  including matching personalities and ages to ensure you enjoy
                  your stay with us.
                </p>
                <p className="layout-readable">
                  Find our current list of fun and inclusive respite homes
                  across Australia below.
                </p>
              </Box>
            </Row>
            <Row>
              <Box>
                <GridWrapper className="flex-ns flex-wrap-ns flex-start-ns mt4">
                  {listings.edges.map(
                    (
                      {
                        node: {
                          title,
                          state,
                          featureImage,
                          listingDescription,
                          ageSuitability
                        }
                      },
                      index
                    ) => (
                      <SilListingCard
                        key={index}
                        href={createStaListingUrl(title)}
                        title={`${title} STA`}
                        state={state}
                        image={featureImage.gatsbyImageData}
                        copy={listingDescription}
                        sdaCategory={undefined}
                        accessible={"No"}
                        linkText="Learn more"
                        categories={ageSuitability.map(
                          (item: string) => ageSuitabilityShort[item]
                        )}
                      />
                    )
                  )}
                </GridWrapper>
              </Box>
            </Row>
          </Container>
        </Section>
        <Container>
          <Row className="flex-wrap">
            <Box className="w-100 w-seven-twelfths-l layout-gap">
              <Heading size={2}>How do I apply for STA?</Heading>
              <p>You need an NDIS plan to stay in one of our STA properties.</p>
              <p>
                You can be referred by support coordinators, disability service
                providers, local community organisations, or your GP.
                Self-referral is also an option. An individual needs assessment
                will be completed on application.
              </p>
              <p>
                Respite rates will be charged as per the{" "}
                <Link
                  className="black fw4"
                  to="https://www.ndis.gov.au/providers/price-guides-and-pricing"
                >
                  NDIS price guide.
                </Link>{" "}
                We can help you understand the Short Term Accommodation (STA)
                funding in your NDIS plan. This is funded under the Core
                Supports – Assistance with Daily Life in your plan.
              </p>
              <p>
                We’d love for you to get in touch with us to discuss what you
                are looking for and how we can support you.
              </p>
              <p className="i">
                Please note: We bill in line with the NDIS price guide, which
                generally increases annually.
              </p>
            </Box>
            <Box className="db dn-l w-100 mt5 mt0-l">
              <DisabilityForm
                formId="disability-sta-contact-us-sm"
                formKind="disability-home-living"
                formTitle="Contact Us"
              />
            </Box>
            <Box className="w-100 w-third-l mt5 mt0-l">
              <ContactBox title="Get in touch">
                <ContactUsPhoneLink
                  marginClasses="mt4"
                  phoneNumber="1800 610 699"
                />
                <ContactUsEmailLink
                  marginClasses="mt3"
                  text="disability.enquiries@lwb.org.au"
                  link="mailto:disability.enquiries@lwb.org.au"
                />
                <div className="dn dib-l tc w-100">
                  <StandardButton
                    className="w-100 mt5"
                    shade="xdark"
                    onClick={() =>
                      scrollToElement("disability-sta-contact-us-l")
                    }
                  >
                    Enquire Now
                  </StandardButton>
                </div>
              </ContactBox>
            </Box>
          </Row>
        </Container>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerImage: file(relativePath: { regex: "/STA.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
    listings: allContentfulStaListing(
      sort: { fields: [state, title], order: ASC }
    ) {
      edges {
        node {
          title
          state
          listingDescription
          ageSuitability
          featureImage {
            ...ForcedAspectRatioRemoteImageAssetQuery
          }
        }
      }
    }
  }
`

export default ShortTermAccommodation
